import React, {useState, useEffect} from 'react'

/*
 * This is the main entry point for your React app
 */
function App() {
  const [data, setData] = useState([{}])

  useEffect(() => {
        fetch("/api/members")
            .then(res => res.json())
            .then(data => {
              setData(data)
              console.log(data)
            })
  }, []);

  return (
    <div>
        {(typeof data.members == 'undefined') ? (
            <p>Loading...</p>
        ) : (
            data.members.map((member, i) => (
                <p key={i}> API Friend from 127: {member}</p>
                )
            )
        )}
    </div>
  );
}

export default App;
